import React, { Component } from 'react';
import PropTypes from 'prop-types';

import logo from './assets/travpro-logo.png';
import clock from './assets/clock.png';

const Section = ({ title, links }) => (
  <div>
    <h2 className="subHeaders">
      {title}
    </h2>
    <ul>
      {Object.keys(links).map((key) => (
        <li key={key}>
          <a href={links[key]} target="_blank" rel="noopener noreferrer">
            {key}
          </a>
        </li>
      ))}
    </ul>
  </div>
);

const Clock = () => (
  <div className="clock-overlay">
    <img src={clock} alt="clock overlay" />
  </div>
);

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: null,
      beer: false,
    };
  }

  componentDidMount() {
    this.fetchContent(10000);

    setInterval(() => {
      const { beer } = this.state;
      const date = new Date(Date.now());

      const {
        groups: { day, time },
      } = (/^(?<day>[A-Z][a-z]{2}).*?(?<time>[0-9]{2}): [0-9]{2}:[0-9]{2}/g).exec(
        date,
      ) || { groups: { day: null, time: null } };

      if (day === 'Fri' && time === '16') {
        this.setState({ beer: true });
      } else if (beer) this.setState({ beer: false });
    }, 1000);
  }

  fetchContent(interval = false) {
    fetch('https://launcher.travpromobile.com/content.json')
      .then((response) => response.json())
      .then((content) => {
        this.setState({ content });
        if (interval !== false) {
          setInterval(() => {
            this.fetchContent();
          }, interval);
        }
      });
  }

  render() {
    const { beer, content } = this.state;

    const { clientWidth } = document.querySelector('.app') || { clientHeight: 600 };

    return (
      content && (
        <div className="app">
          <div className="modal-header">
            <h1 className="logo">
              <img className="logo-icon" src={logo} alt="title-logo" />
              TravPro Launcher
            </h1>
          </div>
          <div className="modal-container">
            {beer && <Clock />}
            <ul>
              {Object.keys(content).map((key) => (
                <Section title={key} links={content[key]} key={key} />
              ))}
            </ul>
          </div>
        </div>
      )
    );
  }
}

Section.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.shape().isRequired,
};
